import {
  type AppManifestBuilder,
  type IHttpClient,
  type TFunction,
  type useFedopsLogger,
  type ExperimentsBag,
} from '@wix/yoshi-flow-editor';
import type {
  EditorSDK,
  ComponentRef,
  ComponentDefinition,
} from '@wix/platform-editor-sdk';
import {
  getComponentFormId,
  isFormsLimitReached,
  showUpgradeModal,
} from '../utils';
import { HELP_ARTICLE_ID } from '../../constants/help';
import { FORM_APP_WIDGET_ID } from '../../constants/app';
import { listForms } from '@wix/ambassador-forms-v4-form/http';
import { NAMESPACE } from '../../constants/namespace';

type FedopsLogger = ReturnType<typeof useFedopsLogger>;

export const widgetGfppBuilder =
  ({
    t,
    editorSDK,
    appToken,
    httpClient,
    fedopsLogger,
    getExperiments,
  }: {
    t: TFunction;
    editorSDK: EditorSDK;
    appToken: string;
    httpClient: IHttpClient;
    fedopsLogger: FedopsLogger;
    getExperiments: () => ExperimentsBag;
  }) =>
  async (appManifestBuilder: AppManifestBuilder) =>
    appManifestBuilder.configureWidget(FORM_APP_WIDGET_ID, (widgetBuilder) =>
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          behavior: 'DEFAULT2',
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('mainAction1', {
          label: t('gfpp_editForm'),
          onClick: ({ detail: { componentRef } }) => {
            fedopsLogger.interactionStarted('open_edit_form_modal');
            handleEditFormClick({
              componentRef,
              appToken,
              editorSDK,
              httpClient,
              fedopsLogger,
              getExperiments,
            });
          },
        })
        .set('help', { id: HELP_ARTICLE_ID }),
    );

interface HandleEditFormClickParams {
  componentRef: ComponentRef;
  appToken: string;
  editorSDK: EditorSDK;
  httpClient: IHttpClient;
  fedopsLogger: FedopsLogger;
  getExperiments: () => ExperimentsBag;
}

const handleEditFormClick = async ({
  componentRef,
  appToken,
  editorSDK,
  httpClient,
  fedopsLogger,
  getExperiments,
}: HandleEditFormClickParams) => {
  const component: ComponentDefinition = await editorSDK.components.serialize(
    appToken,
    {
      componentRef,
    },
  );

  const formId = getComponentFormId(component);

  if (formId) {
    const response = await httpClient.request(
      listForms({
        formIds: [formId],
        namespace: NAMESPACE,
        paging: { limit: 1 },
      }),
    );

    await gfppOpenFormComposer(
      editorSDK,
      appToken,
      response?.data?.forms?.length ? formId : '',
      fedopsLogger,
    );
  } else {
    const formLimitReached = await isFormsLimitReached(httpClient);

    if (formLimitReached) {
      await showUpgradeModal(editorSDK, appToken);
    } else {
      await gfppOpenFormComposer(editorSDK, appToken, '', fedopsLogger);
    }
  }
};

const gfppOpenFormComposer = async (
  editorSDK: EditorSDK,
  appToken: string,
  formId: string = '',
  fedopsLogger: FedopsLogger,
) => {
  await editorSDK.editor.openDashboardPanel(appToken, {
    url: `/wix-forms/form/${formId ?? ''}`,
    closeOtherPanels: false,
  });

  await editorSDK.application.livePreview.refresh(appToken, {
    shouldFetchData: false,
    source: 'gfpp_edit_form',
  });
  fedopsLogger.interactionEnded('open_edit_form_modal');
};
