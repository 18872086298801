import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import {
  type Color,
  convertStyles,
  extractBorderRadius,
  type Font,
  type FontMap,
  type FontOverrides,
} from './style-utils';

type TextInputStyle = {
  'alpha-bgf': string | undefined;
  bgh: string | undefined;
  shd: string | undefined;
  rd: string | undefined;
  txtd: string | undefined;
  'alpha-bge': string | undefined;
  bge: string | undefined;
  'alpha-brdh': string | undefined;
  'alpha-brd': string | undefined;
  fntlbl: string | undefined;
  'alpha-bg': string | undefined;
  bg: string | undefined;
  txt2: string | undefined;
  txtlbl: string | undefined;
  txt: string | undefined;
  bgf: string | undefined;
  txtlblrq: string | undefined;
  'alpha-bgh': string | undefined;
  'alpha-brde': string | undefined;
  brwh: string | undefined;
  brw: string | undefined;
  'alpha-brdf': string | undefined;
  fnt: string | undefined;
  brd: string | undefined;
  brdf: string | undefined;
  'boxShadowToggleOn-shd': string | undefined;
  brdd: string | undefined;
  brwe: string | undefined;
  bgd: string | undefined;
  brdh: string | undefined;
  brde: string | undefined;
  brwf: string | undefined;
};

type InputFieldStyle = {
  colors: {
    inputBackgroundColor: Color;
    inputBackgroundColorHover: Color;
    inputBorderColor: Color;
    inputBorderColorHover: Color;
    inputLabelColor: Color;
    inputValueColor: Color;
    inputNoteColor: Color;
    inputPlaceholderColor: Color;
    inputSelectionColor: Color;
    inputErrorColor: Color;
    linkColor: Color;
    paragraphColor: Color;
  };
  fonts: {
    inputLabelFont: Font;
    inputValueFont: Font;
    inputNoteFont: Font;
    paragraphFont: Font;
  };
  numbers: {
    inputBorderStyle: number | undefined;
    inputBorderWidth: number | undefined;
    inputBorderRadius: number | undefined;
  };
};

enum InputFieldBorderStyle {
  None = 0,
  AllSides = 1,
  Bottom = 2,
}

const TOKEN = 'TOKEN';

function debug(...args: unknown[]) {
  console.log('migration >', ...args);
}

export async function getTextInputStyle(
  sdk: EditorSDK,
  textInputRef: ComponentRef,
) {
  const TAG = `textInputRef: ${textInputRef.id} >`;
  const textInputStyle = await sdk.components.style.get(TOKEN, {
    componentRef: textInputRef,
  });

  if (!textInputStyle) {
    debug(TAG, 'text input style not found');
  }

  debug(TAG, 'resolved text input style', textInputStyle);

  const fontMap: FontMap = await sdk.theme.fonts.getMap(TOKEN);

  const properties: TextInputStyle = textInputStyle.style.properties;
  const propertiesSource: TextInputStyle =
    textInputStyle.style.propertiesSource;

  const borderInfo = extractBorderInfo(properties.brw);
  const hasBorders =
    borderInfo?.style === InputFieldBorderStyle.AllSides ||
    borderInfo?.style === InputFieldBorderStyle.Bottom;

  const toColor = (name: keyof TextInputStyle): Color => {
    const value = properties[name];
    const valueSource = propertiesSource[name] ?? 'value';

    const alphaName = `alpha-${name}` as keyof TextInputStyle;
    const alpha = parseFloat(properties[alphaName] ?? '1');
    const alphaSource = propertiesSource[alphaName] ?? 'value';

    return value ? { value, valueSource, alpha, alphaSource } : undefined;
  };

  const toFont = (
    name: keyof TextInputStyle,
    overrides?: FontOverrides,
  ): Font => {
    const value = properties[name];
    const valueSource = propertiesSource[name] ?? 'value';

    return value && valueSource ? { value, valueSource, overrides } : undefined;
  };

  const styleMap: InputFieldStyle = {
    colors: {
      inputBackgroundColor: toColor('bg'),
      inputBackgroundColorHover: toColor('bgh'),
      inputBorderColor: toColor('brd'),
      inputBorderColorHover: toColor('brdh'),
      inputLabelColor: toColor('txtlbl'),
      inputValueColor: toColor('txt'),
      inputPlaceholderColor: toColor('txt2'),
      inputErrorColor: toColor('brde'),
      inputNoteColor: toColor('txtlbl'), // no direct mapping, using same as label color
      inputSelectionColor: toColor('txt'), // no direct mapping, using same as value
      linkColor: toColor('txtlbl'), // no direct mapping, using same as label color
      paragraphColor: toColor('txtlbl'), // no direct mapping, using same as label color
    },
    fonts: {
      inputLabelFont: toFont('fntlbl'),
      inputValueFont: toFont('fnt'),
      inputNoteFont: toFont('fntlbl', { bold: false }), // no direct mapping, using same as label font
      paragraphFont: toFont('fntlbl', { bold: false, sizeOffset: 1 }), // no direct mapping, using same as label font
    },
    numbers: {
      inputBorderStyle: borderInfo?.style,
      inputBorderWidth: hasBorders ? borderInfo.width : undefined,
      inputBorderRadius: hasBorders
        ? extractBorderRadius(properties.rd)
        : undefined,
    },
  };

  const remappedStyle = convertStyles(styleMap, fontMap);

  debug(TAG, 'remapped text input style', remappedStyle);

  return remappedStyle;
}

function extractBorderInfo(width: string | undefined) {
  if (!width) {
    return;
  }

  const parts = width.split(' ');
  if (parts.length === 1) {
    const value = parseInt(parts[0], 10);
    const style =
      value === 0 ? InputFieldBorderStyle.None : InputFieldBorderStyle.AllSides;

    return { width: value, style };
  }

  const [top, right, bottom, left] = parts;
  void right;
  void left;
  const value = bottom ?? top;

  if (!value) {
    return;
  }

  return { width: parseInt(value, 10), style: InputFieldBorderStyle.Bottom };
}
